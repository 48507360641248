<script>
import WidgetTitle from '@/components/widgets/widgetTitle'

export default {
  name: 'ClasseWidget',
  components: {
    WidgetTitle
  },
  computed: {
    classes () {
      return this.$store.getters['Compte/dataSalles']
    }
  }
}
</script>

<template lang="pug">
  .classe-widget
    WidgetTitle(:title="$t('menu.mes-classes')" :length="classes.length" :toutVoirLink="{ name: 'classes___' + $i18n.locale, params: { lang: $i18n.locale } }")
    v-list
      v-list-item(v-for='(classe, index) in classes.slice(0, 5)', :key='classe.id', :to='{ name: "classesdetail___" + $i18n.locale, params: { id: classe, lang: + $i18n.locale } }')
        v-list-item-avatar(color="blue lighten-5")
          div {{ 'C' + index }}
        v-list-item-content
          v-list-item-title {{ classe.titre }}
          v-list-item-subtitle
            span {{ classe.enseignant.nom }},
            span  {{ $tc('partage.eleves'), classe.eleves.length, { nombre: classe.eleves.length } }}
          v-list-item-subtitle
            span(v-if="classe.debut") {{ $t('classe.creee') + $moment(classe.debut).format('DD/MM/YYYY') }}
            span  {{ $t('classe.prend-fin') + $moment(classe.fin).format('DD/MM/YYYY')}}
        .actions
          v-btn(fab, depressed, color='secondary', small, :to='{ name: "classesdetail___" + $i18n.locale, params: { id: classe, lang: + $i18n.locale } }')
            font-awesome-icon(:icon="['far', 'arrow-right']")
</template>

<style lang="sass" scoped>
.classe-widget
  margin: 2rem 1rem
</style>
