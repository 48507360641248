<script>
export default {
  name: 'WidgetTitle',
  props: {
    title: { type: String, required: true },
    toutVoirLink: { type: Object, required: false },
    transparentBg: { type: Boolean, default: false },
    length: { type: Number, required: false, default: null }
  },
  computed: {
    lengthString () {
      return this.length ? ` (${this.length})` : ''
    }
  }
}
</script>

<template lang="pug">
  .header
    .widget-title(:class="{ transparentBg }") {{ title + lengthString}}
      v-btn(v-if="toutVoirLink", rounded, outlined, small, :to="toutVoirLink") {{ $t('action.tout-voir') }}
</template>

<style lang="sass" scoped>
.widget-title
  display: flex
  align-items: center
  justify-content: space-between
  border-radius: 15px 15px 0 0
  background-color: var(--v-primary-base)
  text-align: left
  padding: 18px 30px
  font-family: "Source Serif Pro", serif
  font-size: 30px
  font-weight: 800
  &.transparentBg
    background: transparent
  .v-btn
    font-family: "Red Hat Text", sans-serif
</style>
