<script>
import ProduitCard from '@/components/presentation/produitCard.vue'
import WidgetTitle from '@/components/widgets/widgetTitle'

export default {
  name: 'CreditWidget',
  components: {
    ProduitCard,
    WidgetTitle
  },
  data () {
    return {
      produits: [],
      loading: true,
      disabledClick: false
    }
  },
  apollo: {
    produits: {
      query: require('@/graphql/queries/v2/searchBoutiqueProduitsItems.gql'),
      variables () {
        return {
          limit: 5
        }
      },
      update (data) {
        this.loading = false
        return data.searchBoutiqueProduitsItems.resultat
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  }
}
</script>

<template lang="pug">
  .documents-widget
    WidgetTitle(:title="$t('menu.mes-cahiers')" :length="produits.length" :toutVoirLink="{ name: 'produits___' + $i18n.locale, params: { lang: $i18n.locale } }", transparentBg)
    .scrollable-content__wrapper
      perfect-scrollbar.section__scroller(v-dragscroll, @dragscrollstart="disabledClick = true", @dragscrollend="disabledClick = false", :class='{"disable-click" : disabledClick}')
        .section__wrapper(v-if='!loading', :key='!loading')
          template(v-if='produits.length > 0')
            template(v-for='(produit, index) in produits', v-if='index < 2')
              produit-card(:key='index', :produit='produit.produit')
            div.produit-card
              .produit-image.accent
              router-link.produit-content.white--text.no-underline(:to="{ name: 'produits___' + $i18n.locale, params: { lang: $i18n.locale } }")
                .produit-title.relative {{ $t('action.tout-voir-document') }}
                font-awesome-icon.f3.mt2.relative(:icon="['far', 'arrow-right']")
          div.empty-container__wrapper.tc(v-else)
            p.black--text {{ $t('message.aucun-document') }}
        .section__wrapper(v-else)
          v-skeleton-loader.activity-preview(v-for='n in 5', :key='n', type='card')
</template>

<style lang='sass' scoped>
.documents-widget
  margin: 1rem
  .section__wrapper
    display: flex
</style>
