<script>
import WidgetTitle from '@/components/widgets/widgetTitle'
import { uniq } from 'lodash-es'

export default {
  name: 'EleveWidget',
  components: {
    WidgetTitle
  },
  computed: {
    eleves () {
      return this.$store.getters['Compte/dataEleves'].slice(0, 5)
    }
  },
  methods: {
    enseignantsFromSalles (salles) {
      return uniq(salles.map(salle => salle.enseignant.nom)).join(', ')
    }
  }
}
</script>

<template lang="pug">
  .eleve-widget
    WidgetTitle(:title="$t('menu.mes-eleves')" :length="eleves.length")
    v-list
      v-list-item(v-for='(eleve, index) in eleves', :key='eleve.id', to='/')
        v-list-item-avatar(color="blue lighten-5")
          div {{ eleve.nom[0] }}
        v-list-item-content
          v-list-item-title {{ eleve.nom }}
          v-list-item-subtitle {{ $t('partage.derniere-co')}}: {{ eleve.connexion ? $moment(eleve.connexion).fromNow() : $t('partage.never-co') }}
          v-list-item-subtitle {{ $tc('enseignant.enseignant', enseignantsFromSalles(eleve.salles).length) }} {{ enseignantsFromSalles(eleve.salles) }}
        .actions
          v-btn(fab, depressed, color='secondary', small, to='/')
            font-awesome-icon(:icon="['far', 'arrow-right']")
</template>

<style lang="sass" scoped>
.eleve-widget
  margin: 2rem 1rem
</style>
