<script>
import ActionsWidget from '@/components/widgets/actionsWidget.vue'
import ClasseWidget from '@/components/widgets/classeWidget.vue'
import EleveWidget from '@/components/widgets/eleveWidget.vue'
import EnseignantWidget from '@/components/widgets/enseignantWidget.vue'
import CreditWidget from '@/components/widgets/creditWidget.vue'
import AchatWidget from '@/components/widgets/achatWidget.vue'

export default {
  name: 'home',
  components: {
    ActionsWidget,
    ClasseWidget,
    EleveWidget,
    EnseignantWidget,
    CreditWidget,
    AchatWidget
  },
  data () {
    return {
      conseilsBoard: false,
      conseilsBoardContent: false,
      ajoutAlerte: false
    }
  },
  methods: {
  }
}
</script>

<template lang='pug'>
.home-index
  v-alert.mt3(light, border='left', colored-border, color='error', elevation='3', dismissible)
    div
    template(v-slot:prepend)
      font-awesome-icon.ml2.mr3.f2.secondary--text(:icon="['fad', 'construction']")
    v-row(align='center')
      v-col(style='padding: 0 12px') {{ $t('message.bienvenue') }}
  .main-content__wrapper
    .espace
      actions-widget
      enseignant-widget
      classe-widget
      eleve-widget
      achat-widget
      credit-widget
</template>
<style lang='sass'>
@import 'src/styles/components/widgets/_widgetBase'
</style>
