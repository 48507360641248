<script>
import WidgetTitle from '@/components/widgets/widgetTitle'

export default {
  name: 'EnseignantWidget',
  components: {
    WidgetTitle
  },
  computed: {
    enseignants () {
      return this.$store.getters['Compte/dataEnseignants']
    }
  }
}
</script>

<template lang="pug">
  .enseignant-widget
    WidgetTitle(:title="$t('menu.mes-enseignants')" :length="enseignants.length" :toutVoirLink="{ name: 'enseignants___' + $i18n.locale, params: { lang: $i18n.locale } }")
    v-list
      v-list-item(v-for='(enseignant, index) in enseignants.slice(0, 5)', :key='index', :to='{ name: "enseignantdetail___" + $i18n.locale, params: { id: enseignant.id } }')
        v-list-item-avatar(color="blue lighten-5")
          div {{enseignant.nom[0].toUpperCase()}}
        v-list-item-content
          v-list-item-title {{enseignant.nom}}
          v-list-item-subtitle
            span {{ $tc('partage.classes', enseignant.nb_eleve, { nombre: enseignant.salles.length }) }},
            span  {{ $tc('partage.eleves', enseignant.nb_eleve, { nombre: enseignant.nb_eleve }) }}
        .actions
          v-btn(fab, depressed, color='secondary', small, :to='{ name: "enseignantdetail___" + $i18n.locale, params: { id: enseignant.id } }')
            font-awesome-icon(:icon="['far', 'arrow-right']")
</template>

<style lang="sass" scoped>
.enseignant-widget
  margin: 2rem 1rem
</style>
