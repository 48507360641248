<script>
import PriceFormat from '@/mixins/priceFormat'
import DateFormat from '@/mixins/dateFormat'
import WidgetTitle from '@/components/widgets/widgetTitle'

export default {
  name: 'AchatWidget',
  components: { WidgetTitle },
  mixins: [PriceFormat, DateFormat],
  data () {
    return {
      listAchat: [],
      loading: true
    }
  },
  apollo: {
    commandes: {
      query: require('@/graphql/queries/v2/boutiqueCommandes.gql'),
      variables () {
        return {
          limit: 5
        }
      },
      update (data) {
        this.listAchat = data.boutiqueCommandes.resultat
        this.loading = false
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  }
}
</script>

<template lang="pug">
  .achat-widget

    WidgetTitle(:title="$t('menu.mes-commandes')" :length="listAchat.length" :toutVoirLink="{ name: 'commandes___' + $i18n.locale }")
    v-list
      template(v-if='loading')
        v-list-item(v-for='load in 5', :key='load')
          v-skeleton-loader.w-100.pv2.ph3(type="list-item-avatar-two-line")
          v-divider(v-if='load < 4')
      template(v-else)
        template(v-if='listAchat.length > 0')
          v-list-item(v-for='(achat, index) in listAchat.slice(0, 5)', :key='index')
            v-list-item-content
              v-list-item-title {{achat.titre}}
              v-list-item-subtitle {{ $t('commandes.achat-date', { date: dateFormat(achat.createdAt) }) }} - {{priceFormat(achat.total)}}
            .actions
              v-btn(fab, depressed, color='secondary', small, :to='{ name: "commandesdetail___" + $i18n.locale, params: { lang: $i18n.locale, id: achat.id } }')
                font-awesome-icon(:icon="['far', 'arrow-right']")
        template(v-else)
          v-list-item
            v-list-item-title {{ $t('message.aucun-achat') }}
</template>

<style lang="sass" scoped>
.achat-widget
  margin: 1rem
</style>
