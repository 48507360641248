<script>
import TextPattern from '@/components/shared/textPattern.vue'

export default {
  name: 'ProduitCard',
  components: {
    TextPattern
  },
  props: {
    produit: { type: Object, required: true },
    size: { type: String, required: false, default: '' },
    listView: { required: false, type: Boolean, default: false },
    finAt: { required: false, type: String, default: '' },
    debutAt: { required: false, type: String, default: '' },
    nuage: { required: false, type: String, default: '' }
  },
  data () {
    return {
      showMore: false,
      showDescription: false
    }
  },
  computed: {
    couverture () {
      if (this.produit && this.produit.image && this.produit.image.medium_image) {
        return { couverture: 'https://plus.dokoma.com' + this.produit.image.medium_image, thumbnail: 'https://plus.dokoma.com' + this.produit.image.thumbnail }
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const couv = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-couverture'))
        if (couv && couv.thumbnail) {
          return { couverture: couv.medium_image, thumbnail: couv.thumbnail }
        } else {
          return null
        }
      }
      return null
    },
    picto () {
      if (this.produit && this.produit.image_picto && this.produit.image_picto.url_to_image) {
        return this.produit.image_picto.url_to_image.includes('svg') ? this.produit.image_picto.url_to_image : 'https://plus.dokoma.com' + this.produit.image_picto.url_to_image
      } else if (this.produit && this.produit.images && this.produit.images.length > 0) {
        const picto = this.produit.images.find(e => e.tagsVisibles.find(t => t.id === 'details-graphiques_image-pictogramme'))
        if (picto) {
          return picto.thumbnail
        }
      }
      return null
    },
    queryLink () {
      if (this.produit) {
        if (this.produit.__typename === 'BoutiqueItem') {
          return { id: this.produit.id, nuage: true }
        } else {
          return { id: this.produit.id }
        }
      } else {
        return null
      }
    },
    currentUrl () {
      return { name: 'produitsdetail___' + this.$i18n.locale, params: this.queryLink }
    }
  },
  methods: {
    toggleDescription () {
      this.showDescription = !this.showDescription
    },
    truncate (text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...'
      } else {
        return text
      }
    }
  }
}
</script>

<template lang='pug'>
router-link.produit-card.no-underline(:to="currentUrl", :class='{"list-view" : listView, "small-card" : size === "small"}')
  .produit-description(:class='{"visible-description" : showDescription}')
    text-pattern(:data='produit.titre', :color='"var(--v-secondary-base)"', :size='40', :opacity='0.05', :quantity='100')
    div.f5.relative(v-if='produit.description', v-html='truncate(produit.description, $store.state.App.mobileTemplate ? 150 : 400)')
    div.flex-grow-1
    div
      v-btn(:to="{ name: 'produitsdetail___' + $i18n.locale, params: { id: produit.id }}", v-ripple, color='secondary', rounded, block)
        span Voir le document
        font-awesome-icon.f6.ml2(:icon="['fas', 'arrow-right']")
  .produit-image()
    v-img.relative.h-100(v-if='couverture && couverture.couverture', :src='couverture.couverture', :lazy-src='couverture.thumbnail')
    text-pattern(v-else-if='produit.titre', :data='produit.titre', :color='"var(--v-secondary-base)"', :size='40', :opacity='0.1', :quantity='100')

  .produit-content()
    .actions-header
      v-btn.description-button.mr1(v-if='produit.description', icon, small, color='light', @click.prevent='showDescription = !showDescription')
        font-awesome-icon(:icon="['fas', showDescription ? 'times' : 'info']", style='font-size: 18px')
    v-avatar.mb2.relative(v-if='picto', size='45', color='transparent')
      div.color-fallback()
      img.avatar-image(:src='picto', type='svg')
    .produit-title.relative {{produit.titre}}
    .produit-infos.relative
      //- .produit-niveau {{produit.niveau}}
      //- .produit-categorie {{produit.matiere}}
    .extra-content__wrapper.relative
      span.produit-description {{produit.description}}
</template>
<style lang='sass'>
@import 'src/styles/components/products/_productCards'
.produit-subtitle
  line-height: 1
  font-family: $display-font
  font-size: 18px
</style>
